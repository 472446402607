export const environment = {
    e2e: false,
    production: false,
    firebase: {
        apiKey: "AIzaSyBHXWUiG_A4ydebuVnwv0MHgt1t0iECSm8",
        authDomain: "starfinanz.cwl.camp"
    },
    region: 'europe-west1',
    brain_base_url: 'https://starfinanz.api.deloitte.camp',
    breadcrumbs: {
        assetOwner: {
            aoh: "Asset Owner Hub",
            assetManagement: "Asset Management",
            principleAssetQuestions: "Principle asset questions",
            offerRequestDetail: "Offer request detail",
            assetPreview: "Preview",
        },
        store: {
            deloitteAiCatalog: "Deloitte.Ai",
            checkout: "Checkout",
            standingOrderDetail: "Standing order detail",
            storefront: "Storefront",
            orderHistory: "Order History",
            myEntitlements: "My Entitlements"
        }
    },
    aoh_url: 'https://aoh.starfinanz.cwl.camp/',
    store_url: 'https://starfinanz.cwl.camp/',
    api_base_url: 'https://starfinanz.api.deloitte.camp',
    authParams: ['oidc.campfire'],
    signInType: 'signInWithPopup',
    hasEnterpriseAssetSearch: false,
    showBarrierQuestions: false,
    asset_redirect_uri: {},
    showInvitatio: false,
    hidePackagesAndPlans: true,
    hideContracting: true,
    hideSupport: true,
    aohQuestions: {
        hide_onboardingWelcomePopup: true,
        business_hideDelegationRadio: true,
        business_hideServiceOfferings: true,
        business_hideIsIndustryAligned: true,
        business_hideIndustries: true,
        business_hideAlliances: true,
        business_hideRevenueForm: true,
        business_hideGeographyQuestion: true,
        business_hideBusinessLineAlignment: true,
        technical_hideContainerizedQuestion: true,
        technical_hideInformOnboardingTeamButton: true,
        hideBeforeYouStartPopup: true,
        business_hideOfficeAddress: true
    },
    hideBarrierSuccessPopup: true,
    postBarrierQuestionsRoute: ['technical','onboarding'],
    onboarding: {
        displayVideo: false,
        video_url: 'https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Deloitte%20AI%20Video/Deloitte%20AI%20-%20Asset%20Owner%20Hub.mp4',
        development: {
            frontend_url: 'https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Frontend_Development_Kit/Deloitte.AI%20Frontend%20Development%20Kit.pdf',
            backend_url: 'https://bitbucket.de.deloitte.com/projects/AI/repos/dcsp-python-framework/browse',
            business_support_email: 'mailto:campfire@deloitte.de?subject=Question%20regarding%20Deloitte%20AI%20Marketplace',
        },
    },
    barrierQuestionsAbort: {
        mailAddress: 'mahorst@deloitte.de',
    },
    support: {
        ccEmail: "mahorst@deloitte.de",
        contact: "mailto:campfire@deloitte.de?subject=Question%20regarding%20Deloitte%20AI%20Marketplace",
    },
    onboarding_options: {
        "gen_ai": {
            api_url: "https://starfinanz-geniusai.connect.api.deloitte.ai",
            app_url: "https://geniusai.starfinanz.cwl.camp/home"
        }
    }
};
